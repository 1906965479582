<template>
  <ha-modal
    class="ModalLogin"
    close-id="Explore_Modal_Login_Close"
    v-bind="$attrs"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('modal.login.title') }}
    </template>
    <template #body>
      <form @keyup.enter="onConnect">
        <HaValidatorInput
          v-slot="{ valid, validated, errors }"
          v-model="models.login"
          rules="email|required"
          name="email"
          slim
        >
          <HaFormField :is-valid="validated ? valid : null">
            <template #label>
              {{ $t('modal.login.email') }}
            </template>
            <HaInput
              v-model="models.login"
              name="email"
              :is-valid="validated ? valid : null"
            />
            <template #error>
              {{ errors[0] }}
            </template>
          </HaFormField>
        </HaValidatorInput>
        <HaValidatorInput
          v-slot="{ valid, validated, errors }"
          v-model="models.password"
          rules="required"
          name="password"
          slim
          ><HaFormField :is-valid="validated ? valid : null">
            <template #label>
              {{ $t('modal.login.password') }}
            </template>
            <ha-password-input
              v-model="models.password"
              :is-valid="validated ? valid : null"
              required
              name="password"
            />
            <template #error>
              {{ errors[0] }}
            </template>
          </HaFormField>
        </HaValidatorInput>
        <div class="ModalLogin--CtaWrapper">
          <ha-button
            :href="firstTimeConnectLink"
            size="small"
            variant="link"
          >
            {{ $t('modal.login.firstTimeConnect') }}
          </ha-button>
          <ha-button
            :href="forgottenPasswordUrl"
            size="small"
            variant="link"
            data-ux="Explore_Modal_Login_ForgottenPassword"
          >
            {{ $t('modal.login.forgotPassword') }}
          </ha-button>
        </div>
      </form>
    </template>
    <template #footer>
      <div class="btn">
        <ha-button
          :disabled="isFetching"
          :loading="isFetching"
          class="btn__submit"
          data-ux="Explore_Modal_Login_SignIn"
          @click="onConnect"
        >
          {{ $t('modal.login.connect') }}
        </ha-button>
      </div>
    </template>
  </ha-modal>
</template>

<script>
import {
  HaModal,
  HaInput,
  HaPasswordInput,
  HaButton,
  useHeaderPublic,
  useNotifications,
  HaValidatorInput,
  HaFormField
} from '@ha/components-v3'
import useCookies from '@/composables/useCookies'
import useConfigEnv from '@/composables/useConfigEnv'
import { useI18n } from '#imports'
import { useUserStore } from '@/store/user.store'

export default {
  name: 'ModalLogin',
  components: {
    HaModal,
    HaValidatorInput,
    HaInput,
    HaPasswordInput,
    HaButton,
    HaFormField
  },
  model: {
    event: 'change'
  },
  emits: ['login', 'close'],
  setup() {
    const { setUser, setOrganizations } = useHeaderPublic()
    const { pushNotification } = useNotifications()
    const { getAccessToken } = useCookies()
    const i18n = useI18n()
    const configEnv = useConfigEnv()
    const userStore = useUserStore()

    return {
      setUser,
      setOrganizations,
      userStore,
      pushNotification,
      getAccessToken,
      configEnv,
      i18n
    }
  },
  data() {
    return {
      isFetching: false,
      models: {
        login: null,
        password: null
      }
    }
  },
  computed: {
    firstTimeConnectLink() {
      let redirectionLink = `${this.configEnv?.NUXT_ENV_AUTH_FRONT}/premiere-connexion`

      if (window.location.href.length > 0) {
        redirectionLink += `?redirectTo=${encodeURIComponent(window.location.href)}`
      }

      return redirectionLink
    },
    forgottenPasswordUrl() {
      return `${this.configEnv?.NUXT_ENV_AUTH_FRONT}/mot-de-passe/oubli`
    }
  },
  methods: {
    onConnect() {
      this.isFetching = true

      const { login, password } = this.models

      return this.userStore
        .connect([
          login,
          password,
          this.$route.params?.organization,
          this.getAccessToken()
        ])
        .then((e) => {
          if (
            e.response?.data?.message === 'password_expired' ||
            e.response?.data?.error?.message === 'password_expired'
          ) {
            window.location.href = `${this.configEnv.NUXT_ENV_AUTH_FRONT}/mot-de-passe/expired`
            return
          }
          if (e.isAxiosError) {
            throw new Error('Error in userStore connect method.')
          }

          this.$emit('login')
        })
        .then(() => {
          this.$emit('close')
          this.setUser(this.userStore.profile)
          this.setOrganizations(this.userStore.organizations)
        })
        .catch((error) => {
          console.error(error)
          this.pushNotification({
            type: 'danger',
            title: this.$t('modal.login.errors.login.title'),
            body: this.$t('modal.login.errors.login.message'),
            timeout: 5000
          })
        })
        .finally(() => (this.isFetching = false))
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/modal-login';
</style>
