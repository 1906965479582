import { useRouter, useRoute } from '#imports'
import { useHeaderPublic, useTracking } from '@ha/components-v3'
import useCookies from '@/composables/useCookies'
import useEditMode from '@/composables/useEditMode'
import { useUserStore } from '@/store/user.store'

export default () => {
  const { tracking }: any = useTracking()
  const { setUser, setOrganizations, user } = useHeaderPublic()
  const { clearTokens } = useCookies()
  const { editMode } = useEditMode()
  const router = useRouter()
  const route = useRoute()
  const userStore = useUserStore()

  const onLogOut = async () => {
    await userStore.disconnect()
    tracking?.track('Logout', {
      userId: user.value?.userId
    })
    tracking?.reset()
    setUser(null)
    setOrganizations([])
    clearTokens()
    if (editMode.value) {
      router.push({
        path: `/associations/${route.params.organization}`
      })
    }
  }

  return { onLogOut }
}
